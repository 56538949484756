import { openDB } from 'idb';

class IndexedDBService {
  constructor() {
    this.dbPromise = openDB('book-overflow-game-db', 1, {
      upgrade(db) {
        db.createObjectStore('books', { keyPath: 'id' });
        db.createObjectStore('quotes', { keyPath: 'id' });
      },
    });
  }

  async putDataToIndexedDB(storeName, data) {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.store;
    
    // Clear existing data first
    await store.clear();
    
    // Then add the new data
    await Promise.all(data.map(item => store.put(item)));
    await tx.done;
  }

  async getDataFromIndexedDB(storeName) {
    const db = await this.dbPromise;
    return db.getAll(storeName);
  }

  async fetchData(url) {
    const response = await fetch(url);
    return response.json();
  }

  async loadData() {
    let storedBooks = [];
    let storedQuotes = [];

    if (process.env.REACT_APP_SHOULD_REFRESH_DATA !== 'true') {
      const [books, quotes] = await Promise.all([
        this.getDataFromIndexedDB('books'),
        this.getDataFromIndexedDB('quotes'),
      ]);
      storedBooks = books;
      storedQuotes = quotes;
    }

    if (storedBooks.length && storedQuotes.length) {
      this.books = storedBooks;
      this.quotes = storedQuotes;
    } else {
      const fetchedBooks = await this.fetchData('/data/books.json');
      const fetchedQuotes = await this.fetchData('/data/quotes.json');

      await this.putDataToIndexedDB('books', fetchedBooks);
      await this.putDataToIndexedDB('quotes', fetchedQuotes);
      
      this.books = fetchedBooks;
      this.quotes = fetchedQuotes;
    }
  }

  async getBookById(bookId) {
    const db = await this.dbPromise;
    const tx = db.transaction('books', 'readonly');
    const store = tx.objectStore('books');
    const book = await store.get(bookId);
    await tx.done;
    return book;
  }
}

export default new IndexedDBService();
